import {createSlice} from "@reduxjs/toolkit"

export const adminSlice = createSlice({
    name: "admin",
    initialState: {
        connected: false,
        token: ""
    },
    reducers: {
        getLogged : (state, action) => {
            state.connected = true 
            state.token = action.payload
            localStorage.setItem("token", action.payload)
            
        },
        getLoggedOut : (state) => {
            state.connected = false
            state.token = ""
            localStorage.removeItem("token")
        },
        stayConnected : (state) => {
            state.connected = true
            state.token = localStorage.getItem("token")
        }
    }
})

export const {getLogged, getLoggedOut, stayConnected} = adminSlice.actions
export default adminSlice.reducer
