import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../styles/css/Gallery.css";
import AddFiles from "./admin/AddFiles";
import LightBox from "./LightBox";

const Gallery = ({ data }) => {
    const isLogged = useSelector((state) => state.admin.connected);
    
    let weeks = [];
    data.forEach((image) => {
        if(!weeks.includes(image.semaine)) {
            weeks.push(image.semaine);
        }
    });
    weeks.sort((a, b) => a - b);

    const showGallery = (week) => {
        const gallery = document.querySelector(`.week-${week} .modal-gallery`);
        gallery.classList.toggle("show");
        document.body.classList.toggle("modal-open");
    };

    return (
        <section className="gallery">
            <h2>Photos du chantier 
            {isLogged ?
                <svg className="btnAddFiles-photo" width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 0H3C1.3 0 0 1.3 0 3V17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V3C20 1.3 18.7 0 17 0ZM18 17C18 17.6 17.6 18 17 18H3C2.4 18 2 17.6 2 17V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V17ZM14 11C14.6 11 15 10.6 15 10C15 9.4 14.6 9 14 9H11V6C11 5.4 10.6 5 10 5C9.4 5 9 5.4 9 6V9H6C5.4 9 5 9.4 5 10C5 10.6 5.4 11 6 11H9V14C9 14.6 9.4 15 10 15C10.6 15 11 14.6 11 14V11H14Z"/>
                </svg> : null
            }
            </h2>
            <hr className='subt'/>
            <div className="gallery-container">
                {weeks.map((week) => (
                    <div className={"gallery-week week-"+week} key={week}>
                        <button className="weeksBtn" onClick={() => showGallery(week)}>
                            <h3>Sem-{week}</h3>
                        </button>
                        <div className="modal-gallery">
                            <div className="modal-gallery-content">
                                <div className="modal-gallery-header">
                                    <h2>Semaine {week}</h2>
                                    <button className="close" onClick={() => showGallery(week)}>
                                        <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="modal-gallery-body">
                                    {data.map((image) => (
                                        image.semaine === week && (
                                        <div className="gallery-image" key={image.id}>
                                            <img src={image.lien} alt={"photo semaine-"+week} />
                                        </div>
                                     )))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <LightBox/>
                {isLogged ? <AddFiles type="photo" /> : null}
            </div>
        </section>
    );
}

export default Gallery;