import { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import logo from "../assets/logoV.svg";
import { getChantier } from "../services/apiCall";
import "../styles/css/Accueil.css";
import { getInfoChantier, cleanInfoChantier } from "../services/redux/chantierSlice";

const Accueil = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [password, setPassword] = useState("");
    

    const getChantierInfo = async () => {
        if (password.trim() !== "" && password.trim().length === 6) {
            let data = await getChantier(password);
            if (data.chantier) {
                dispatch(getInfoChantier(data));
                navigate("/chantier");
            } else {
                dispatch(cleanInfoChantier());
                alert("Mot de passe incorrect");
            }
        } else {
            dispatch(cleanInfoChantier());
            alert("Mot de passe incorrect");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        getChantierInfo();
    }

    return (
        <main className="accueil">
            <img src={logo} alt="logo" className="accueil__logo" />
            <form className="form">
                <input type="text" placeholder="Code Chantier" onChange={(e) => setPassword(e.target.value)} className="form__input"/>
                <button type="submit" className="form__button" onClick={handleSubmit}>
                    <svg width="40" height="40" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 0C4.9 0 0 4.9 0 11C0 17.1 4.9 22 11 22C17.1 22 22 17.1 22 11C22 4.9 17.1 0 11 0ZM11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM15.9373 10.7314C15.9172 10.6828 15.9 10.6414 15.9 10.6C15.9 10.5 15.8 10.4 15.7 10.3L11.7 6.3C11.3 5.9 10.7 5.9 10.3 6.3C9.9 6.7 9.9 7.3 10.3 7.7L12.6 10H7C6.4 10 6 10.4 6 11C6 11.6 6.4 12 7 12H12.6L10.3 14.3C9.9 14.7 9.9 15.3 10.3 15.7C10.5 15.9 10.7 16 11 16C11.3 16 11.5 15.9 11.7 15.7L15.7 11.7C15.8 11.6 15.9 11.5 15.9 11.4C16 11.3 16 11.1 16 11C16 10.8828 15.9657 10.8 15.9373 10.7314Z"/>
                    </svg>
                </button>
            </form>
        </main>
    );
}

export default Accueil;