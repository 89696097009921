import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Documents from "./Documents";
import "../styles/css/Files.css";
import { addPersonnelDocument, updateListPersonnel } from "../services/apiCall";


const Personnels = () => {
    const personnels = useSelector((state) => state.chantier.personnels);
    const personnels_all = useSelector((state) => state.personnels.personnels.personnels);
    const chantierId = useSelector((state) => state.chantier.chantier.id);
    const isLogged = useSelector((state) => state.admin.connected);
    const { documents } = useSelector((state) => state.personnels.personnels);
    const [listDocuments, setListDocuments] = useState(documents || []);
    const [listPerso, setListPerso] = useState(personnels);
    const [PersoId, setPersoId] = useState(null);

    const openFile = (e) => {
        const modal = e.currentTarget.nextElementSibling;
        modal.classList.add("open");
        document.body.classList.add("modal-open")
    };

    const openPerso = (e) => {
        const modal = e.currentTarget.parentElement.querySelector(".modal-perso");
        modal.classList.add("open");
        document.body.classList.add("modal-open")
        window.onclick = (event) => {
            if (event.target === modal) {
                closePerso()
            }
        }
    };

    const closePerso = () => {
        const modal = document.querySelector(".modal-perso.open");
        modal.classList.remove("open");
        document.body.classList.remove("modal-open")
    };

    const openListPerso = () => {
        const modal = document.querySelector(".modal-list");
        modal.classList.add("open");
        document.body.classList.add("modal-open")
        window.onclick = (event) => {
            if (event.target === modal) {
                closeListPerso()
            }
        }
        modal.querySelectorAll("input").forEach((input) => {
            input.checked = listPerso.find((perso) => {
                if (perso.id === Number(input.id.split("_")[1])) {
                    return true
                }
                return false
            })
        });
    };

    const closeListPerso = () => {
        const modal = document.querySelector(".modal-list.open");
        modal.classList.remove("open");
        document.body.classList.remove("modal-open")
    };

    const handleListPerso = async (e) => {
        e.preventDefault();
        const modal = document.querySelector(".modal-list");
        let newList = [];
        modal.querySelectorAll("input").forEach((input) => {
            if (input.checked) {
                newList.push(personnels_all.find((perso) => perso.id === Number(input.id.split("_")[1])))
            }
        });

        try {
            const res = await updateListPersonnel(newList, chantierId);
            console.log(res);
            if (res.status === 200) {
                setListPerso(newList);
            }
        } catch (err) {
            console.log(err);
        }

        closeListPerso();
    };

    const openAddFile = () => {
        const modal = document.querySelector(".modal-addFile");
        modal.classList.add("open");
        document.body.classList.add("modal-open")
        window.onclick = (event) => {
            if (event.target === modal) {
                closeAddFile()
            }
        }
    };

    const closeAddFile = () => {
        const modal = document.querySelector(".modal-addFile.open");
        modal.classList.remove("open");
        document.body.classList.remove("modal-open")
        setPersoId(null);
    };

    const handleAddFile = async (e) => {
        e.preventDefault();
        console.log(e.target.files.files[0]);
        const formData = new FormData();
        let nom = e.target.nom.value;
        const file = e.target.files.files[0];
        formData.append("nom", nom);
        formData.append("file", file);
        try {
            const res = await addPersonnelDocument(formData, PersoId);
            console.log(res);
            if (res.status === 200) {
                setListDocuments([...listDocuments, res.data]);
            }
        } catch (err) {
            console.log(err);
        }
        closeAddFile();
    };

    return (
        <section className="personnels">
            <h2>Liste du Personnels
                {isLogged ?
                    <svg className="btnAddTask" onClick={() => openListPerso()} width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 0H3C1.3 0 0 1.3 0 3V17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V3C20 1.3 18.7 0 17 0ZM18 17C18 17.6 17.6 18 17 18H3C2.4 18 2 17.6 2 17V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V17ZM14 11C14.6 11 15 10.6 15 10C15 9.4 14.6 9 14 9H11V6C11 5.4 10.6 5 10 5C9.4 5 9 5.4 9 6V9H6C5.4 9 5 9.4 5 10C5 10.6 5.4 11 6 11H9V14C9 14.6 9.4 15 10 15C10.6 15 11 14.6 11 14V11H14Z" />
                    </svg> : null
                }
            </h2>
            <hr className='subt' />
            <div className="personnels-container">
                {listPerso.map((personnel) => (
                    <div className="personnel" key={personnel.id}>
                        <button className="weeksBtn" onClick={(e) => openPerso(e)}>
                            <h3>{personnel.prenom} {personnel.nom}</h3>
                        </button>
                        <div className="modal modal-perso">
                            <div className="modal-content">
                                <div className="personnel-header">
                                    <h3>{personnel.nom} {personnel.prenom}</h3>
                                    <p>{personnel.poste}</p>
                                    <button className="modal-close" onClick={() => closePerso()}>
                                        <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="files" key={personnel.id}>
                                    <button className="weeksBtn" onClick={(e) => openFile(e)}>
                                        <h3>ProBTP</h3>
                                    </button>
                                    <Documents files={personnel.proBTP} />
                                    {
                                        [...listDocuments].map((doc) => (
                                            doc.personnel_id === personnel.id) ? (
                                            <Fragment key={doc.nom}>
                                                <button className="weeksBtn" onClick={(e) => openFile(e)}>
                                                    <h3>{doc.nom}</h3>
                                                </button>
                                                <Documents files={doc.lien} />
                                            </Fragment>
                                        ) : null
                                        )
                                    }
                                    {isLogged ?
                                        <svg className="btnAddTask" onClick={() => { setPersoId(personnel.id); openAddFile() }} width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 0H3C1.3 0 0 1.3 0 3V17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V3C20 1.3 18.7 0 17 0ZM18 17C18 17.6 17.6 18 17 18H3C2.4 18 2 17.6 2 17V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V17ZM14 11C14.6 11 15 10.6 15 10C15 9.4 14.6 9 14 9H11V6C11 5.4 10.6 5 10 5C9.4 5 9 5.4 9 6V9H6C5.4 9 5 9.4 5 10C5 10.6 5.4 11 6 11H9V14C9 14.6 9.4 15 10 15C10.6 15 11 14.6 11 14V11H14Z" />
                                        </svg> : null
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
            {isLogged ?
                <div className="modal modal-list">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3>Liste du Personnels</h3>
                            <button className="modal-close" onClick={() => closeListPerso()}>
                                <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="personnels-container">
                                <form encType="multipart/form-data" onSubmit={(e) => handleListPerso(e)}>
                                    <div className="list">
                                        {personnels_all.map((personnel) => (
                                            <div key={personnel.id} className="form-group perso">
                                                <label htmlFor={`personnel_${personnel.id}`}>{personnel.nom} {personnel.prenom}
                                                    <input type="checkbox" name={`personnel_${personnel.id}`} id={`personnel_${personnel.id}`} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <button type="submit" className="btn btn-primary">Valider</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            {isLogged ?
                <div className="modal modal-addFile">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3>Ajouter Document</h3>
                            <button className="modal-close" onClick={() => closeAddFile()}>
                                <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                                </svg>
                            </button>
                        </div>
                        <div className="file-form">
                            <form encType="multipart/form-data" onSubmit={(e) => handleAddFile(e)}>
                                <div className="form-group">
                                    <label htmlFor="nom">Nom du fichier </label>
                                    <input type="text" name="nom" id="nom" placeholder="Nom du fichier" required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="files">Fichier</label>
                                    <input type="file" name="files" id="files" accept="application/pdf" required />
                                </div>
                                <button type="submit">Valider</button>
                            </form>
                        </div>
                    </div>
                </div>
                : null}
        </section>
    );
};

export default Personnels;