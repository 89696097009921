import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Documents from "./Documents";
import "../styles/css/Files.css";
import AddFiles from "./admin/AddFiles";

const Files = ({ type, data }) => {
    const chantierId = useSelector((state) => state.chantier.id);
    const isLogged = useSelector((state) => state.admin.connected);
    const documents = useSelector((state) => state.chantier.documents);
    const [files, setFiles] = useState([]);
    let name = "";
    if (type === "bdd") {
        name = "Bon de dechet";
    }

    useEffect(() => {
        const getFiles = async () => {
            setFiles(data);
        };
        getFiles();
    }, [chantierId, documents]);

    const openModal = (e) => {
        const modal = e.currentTarget.nextElementSibling;
        modal.classList.add("open");
        document.body.classList.add("modal-open")
    };

    return (
        <section className={type.toLowerCase()}>
            <h2>{type === "bdd" ? name : type}
            {isLogged ?
                <svg className={`btnAddFiles-${type.toLowerCase()}`} width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 0H3C1.3 0 0 1.3 0 3V17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V3C20 1.3 18.7 0 17 0ZM18 17C18 17.6 17.6 18 17 18H3C2.4 18 2 17.6 2 17V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V17ZM14 11C14.6 11 15 10.6 15 10C15 9.4 14.6 9 14 9H11V6C11 5.4 10.6 5 10 5C9.4 5 9 5.4 9 6V9H6C5.4 9 5 9.4 5 10C5 10.6 5.4 11 6 11H9V14C9 14.6 9.4 15 10 15C10.6 15 11 14.6 11 14V11H14Z"/>
                </svg> : null
            }
            </h2>
            <hr className='subt' />
            <div className={`${type.toLowerCase()}-container`}>
                {files.map((file) => (
                    <div className='file' key={file.id}>
                        <button className="weeksBtn" onClick={(e) => openModal(e)}>
                            <h3>{type === "BSD" || type === "Administratif" ? file.nom : `Sem-${file.semaine < 10 ? "0"+file.semaine : file.semaine}`}</h3>
                        </button>
                        <Documents files={file.lien} />
                    </div>
                ))}
            </div>
            {isLogged ? <AddFiles type={type} /> : null}
        </section>
    );
};

export default Files;