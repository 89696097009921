import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addChantierFiles } from "../../services/apiCall";
import { updateInfoChantierDocuments } from "../../services/redux/chantierSlice";

const AddFiles = ({ type }) => {
    const isLogged = useSelector(state => state.admin.connected);
    const chantier = useSelector((state) => state.chantier.chantier);
    const dispatch = useDispatch();
    const now = new Date();
    const target = new Date(now.getFullYear(), 0, 4);
    const dayDiff = (now - target + (target.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000) / (24 * 60 * 60 * 1000);
    const weekNum = Math.floor((dayDiff + 1) / 7) + 1;

    useEffect(() => {
        (async () => {
            if (isLogged) {
                const btn = document.querySelector(`.btnAddFiles-${type.toLowerCase()}`);
                btn.addEventListener("click", openAddFile);
            }
        })()
    }, [isLogged])

    const closeAddFile = () => {
        const modal = document.querySelector(`.addFiles-${type.toLowerCase()}`);
        modal.style.display = "none";
        document.body.classList.remove("modal-open")
    }

    const openAddFile = () => {
        const modal = document.querySelector(`.addFiles-${type.toLowerCase()}`);
        modal.style.display = "block";
        document.body.classList.add("modal-open")
        window.onclick = (event) => {
            if (event.target === modal) {
                closeAddFile()
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        let semaine = "", nom = "";
        if (type !== "BSD" && type !== "Administratif") {
            semaine = parseInt(e.target.semaine.value) || weekNum;
            nom = '';
        }
        else {
            semaine = 0;
            nom = e.target.nom.value || `${now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}/${now.getFullYear()}`;
        }

        const files = e.target.files.files;
        formData.append("semaine", semaine);
        formData.append("nom", nom);
        formData.append("type", type);
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }
        try {
            const res = await addChantierFiles(formData, chantier.id)
            console.log(res, 1);
            if (res.status === 200) {
                console.log(res.data, 2);
                res.data.forEach((file) => {
                    console.log(file);
                    dispatch(updateInfoChantierDocuments(
                        {
                            id: Math.random().toString(10).substr(2, 9),
                            chantier_id: chantier.id,
                            type: type.toLowerCase(),
                            lien: file.lien,
                            semaine: parseInt(file.semaine),
                            nom: file.nom,
                        }
                    ))
                })
            }
        } catch (error) {
            alert("Une erreur est survenue")
            console.log(error);
        }
        closeAddFile();
    }

    return (
        <div className={`addFiles-${type.toLowerCase()} modal`}>
            <div className="modal-content">
                <div className="modal-header">
                    <h2>
                        {
                            type === "photo" ? "Ajouter des photos" :
                                type === "BSD" ? "Ajouter le BSD du mois" :
                                    type === "bdd" ? "Ajouter les bons de déchets" :
                                        type === "Reporting" ? "Ajouter le reporting" :
                                            type === "Administratif" ? "Ajouter des documents administratifs" :
                                                null
                        }
                    </h2>
                    <button className="modal-close" onClick={() => closeAddFile()}>
                        <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                        </svg>
                    </button>
                </div>
                <form encType="multipart/form-data" onSubmit={(e) => handleSubmit(e)}>
                    {type === "BSD" ?
                        <div className="form-group">
                            <label htmlFor="nom">Mois </label>
                            <input type="text" name="nom" id="nom" pattern={type === "BSD" ? "(0[1-9]|1[0-2])\/20[0-9]{2}" : null} placeholder={type === "BSD" ? `${now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}/${now.getFullYear()}` : "Nom du fichier"} />
                        </div>
                        : type === "Administratif" ?
                        <div className="form-group">
                            <label htmlFor="nom">Nom du fichier </label>
                            <input type="text" name="nom" id="nom" placeholder="Nom du fichier" required />
                        </div>
                        : <div className="form-group">
                            <label htmlFor="semaine">Semaine </label>
                            <input type="number" name="semaine" id="semaine" placeholder={weekNum} />
                        </div>
                    }
                    <div className="form-group">
                        <label htmlFor="files">Fichier(s)</label>
                        {type === "photo" ?
                            <input type="file" name="files" id="files" multiple accept="image/png,image/jpeg" required/> :
                            <input type="file" name="files" id="files" accept="application/pdf" required/>
                        }
                    </div>
                    <button type="submit">Valider</button>
                </form>
            </div>
        </div>
    );
};

export default AddFiles;