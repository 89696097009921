import { createSlice } from "@reduxjs/toolkit"

export const chantierSlice = createSlice({
    name: "chantier",
    initialState: {
        chantier : {
            id: 0,
            nom: "",
            nomChoix: "",
            mdp: "",
            dateDebut: "",
            dateFin: "",
            image: "",
            adresse: "",
            mandataire: "",
            interlocuteur: "",
            finReel: ""
        },
        documents : [],
        planning : [],
        personnels : [],
        pointages : [],
    },
    reducers : {
        getInfoChantier : (state, action) => {
            state.chantier.id = action.payload.chantier.id
            state.chantier.nom = action.payload.chantier.nom
            state.chantier.nomChoix = action.payload.chantier.nomChoix
            state.chantier.dateDebut = action.payload.chantier.dateDebut
            state.chantier.dateFin = action.payload.chantier.dateFin
            state.chantier.image = action.payload.chantier.image
            state.chantier.adresse = action.payload.chantier.adresse
            state.chantier.mandataire = action.payload.chantier.mandataire
            state.chantier.interlocuteur = action.payload.chantier.interlocuteur
            state.chantier.finReel = action.payload.chantier.finReel
            action.payload.documents ? state.documents = action.payload.documents : state.documents = []
            action.payload.plannings ? state.planning = action.payload.plannings : state.planning = []
            action.payload.personnels ? state.personnels = action.payload.personnels : state.personnels = []
            action.payload.pointages ? state.pointages = action.payload.pointages : state.pointages = []
            
        },
        updateInfoChantier: (state, action) => {
            state.chantier.nomChoix = action.payload.nomChoix
            state.chantier.dateDebut = action.payload.dateDebut
            state.chantier.dateFin = action.payload.dateFin
            state.chantier.adresse = action.payload.adresse
            state.chantier.mandataire = action.payload.mandataire
            state.chantier.interlocuteur = action.payload.interlocuteur
        },
        updateInfoChantierPlanning: (state, action) => {
            //if id never use 
            if(state.planning.length > 0){
                state.planning.map((item, index) => {
                    if(item.id === action.payload.id){
                        state.planning[index].tache = action.payload.tache
                        state.planning[index].debutPrevu = action.payload.debutPrevu
                        state.planning[index].finPrevu = action.payload.finPrevu
                        state.planning[index].debutReel = action.payload.debutReel
                        state.planning[index].finReel = action.payload.finReel
                    }
                })
            }
        },
        updateInfoChantierDocuments: (state, action) => {
            state.documents.push(
                {
                    id: action.payload.id,
                    chantier_id: action.payload.chantier_id,
                    type: action.payload.type,
                    lien: action.payload.lien,
                    semaine: action.payload.semaine,
                    nom: action.payload.nom,
                }
            )
        },
        updateInfoChantierPersonnels: (state, action) => {
            state.personnels = action.payload.personnels
        },
        updateInfoChantierPointages: (state, action) => {
            state.pointages = action.payload.pointages
        },
        cleanInfoChantier : (state) => {
            state.chantier.id = 0
            state.chantier.nom = ""
            state.chantier.nomChoix = ""
            state.chantier.dateDebut = ""
            state.chantier.dateFin = ""
            state.chantier.image = ""
            state.chantier.adresse = ""
            state.chantier.mandataire = ""
            state.chantier.interlocuteur = ""
            state.chantier.finReel = ""
        }
    }
})
export const {getInfoChantier, updateInfoChantier, cleanInfoChantier, updateInfoChantierPlanning, updateInfoChantierDocuments, updateInfoChantierPersonnels, updateInfoChantierPointages} = chantierSlice.actions
export default chantierSlice.reducer 