
import { Link } from 'react-router-dom';
import logo from '../assets/ANAF.svg';
import "../styles/css/Header.css";
import Login from './Login'

const Header = () => {
    const openModal = async () => {
        const modal = document.querySelector('.modal');
        modal.classList.add('open');
        document.body.classList.add('modal-open');
    }

    return (
        <header className="header">
            <Link to={"/"} className="header__logo">
                <img src={logo} alt="Logo" />
            </Link>
            <div className="nav">
                <button onClick={() => openModal()}>
                    <h3> Connexion Admin</h3>
                </button>
            </div>
            <Login />
        </header>
    );
}

export default Header;