import { useState } from "react";
import "../styles/css/Login.css";
import { useDispatch } from "react-redux";
import { getLogged } from "../services/redux/adminSlice";
import { useNavigate } from "react-router-dom";
import { getAuthenticated } from "../services/apiCall";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [password, setPassword] = useState("");

    const closeModal = () => {
        const modal = document.querySelector('.modal');
        modal.classList.remove('open');
        document.body.classList.remove('modal-open');
        document.querySelector('.logadmin').reset();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password.trim() !== "" && password.trim().length === 6) {
            getAuthenticated(password).then(data => {
            if (data.token) {
                dispatch(getLogged(data.token));
                closeModal();
            } else {
                alert(data.message);
            }
        });
        } else {
            alert('Veuillez entrer un code admin valide');
        }
    }

    return (
        <div className="modal login">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Connexion Admin</h2>
                    <button className="modal-close" onClick={() => closeModal()}>
                        <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z"/>
                        </svg>
                    </button>
                </div>
                <div className="modal-body">
                    <form className="form logadmin">
                        <input type="text" maxLength={6} placeholder="Code Admin" onChange={(e) => setPassword(e.target.value)} className="form__input"/>
                        <button type="submit" className="form__button" onClick={handleSubmit}>
                            <svg width="40" height="40" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 0C4.9 0 0 4.9 0 11C0 17.1 4.9 22 11 22C17.1 22 22 17.1 22 11C22 4.9 17.1 0 11 0ZM11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM15.9373 10.7314C15.9172 10.6828 15.9 10.6414 15.9 10.6C15.9 10.5 15.8 10.4 15.7 10.3L11.7 6.3C11.3 5.9 10.7 5.9 10.3 6.3C9.9 6.7 9.9 7.3 10.3 7.7L12.6 10H7C6.4 10 6 10.4 6 11C6 11.6 6.4 12 7 12H12.6L10.3 14.3C9.9 14.7 9.9 15.3 10.3 15.7C10.5 15.9 10.7 16 11 16C11.3 16 11.5 15.9 11.7 15.7L15.7 11.7C15.8 11.6 15.9 11.5 15.9 11.4C16 11.3 16 11.1 16 11C16 10.8828 15.9657 10.8 15.9373 10.7314Z"/>
                            </svg>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;