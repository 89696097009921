import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/ANAF.svg';
import "../styles/css/Header.css";
import Login from './Login'
import { useDispatch } from 'react-redux';
import { getLoggedOut } from "../services/redux/adminSlice"
import { useEffect } from 'react';


const AdminHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if ( window.location.pathname === '/') {
            document.querySelector('.nav__link').classList.add('active');
        } else {
            document.querySelector('.nav__link').classList.remove('active');
        }
    }, [window.location.pathname]);

    const logout = async () => {
        dispatch(getLoggedOut());
        navigate('/');
    }

    return (
        <header className="header">
            <Link to={"/"} className="header__logo">
                <img src={logo} alt="Logo" />
            </Link>
            <div className="nav">
                <Link to={"/"} className="nav__link">
                    <h3>Admin</h3>
                </Link>
               <button onClick={() => logout()}>
                    <h3> Deconnexion</h3>
                </button>
            </div>
            <Login />
        </header>
        
    );
}

export default AdminHeader;