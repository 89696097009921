/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "../styles/css/LightBox.css";

const LightBox = () => {
    const documents = useSelector((state) => state.chantier.documents);
    let lightBox = ""
    let lightBoxImage = ""
    let imgSrc = "";
    let weeks = [];

    useEffect(() => {
        const getWeek = async (e) => {
            weeks = document.querySelectorAll(".gallery-week");
            lightBox = document.querySelector(".lightbox");
            lightBoxImage = document.querySelector(".lightbox-img");
            if (weeks) {
                weeks.forEach((week) => {
                    let images = week.querySelectorAll(".gallery-image");
                    images.forEach((image) => {
                        image.addEventListener("click", (e) => {
                            imgSrc = e.target.src;
                            lightBox.classList.add("show");
                            lightBoxImage.src = imgSrc;
                            window.onclick = (event) => {
                                if(event.target === lightBox) {
                                    lightBox.classList.remove("show");
                                }
                            }
                        });
                    });
                });
            }
        }
        getWeek();
    }, [ lightBox, lightBoxImage, imgSrc, documents ]);

    const nextImage = () => {
        if(weeks) {
            weeks.forEach((week) => {
                let images = Array.from(week.querySelectorAll(".gallery-image"));
                images.reverse();
                images.forEach((image) => {
                    if(image.querySelector("img").src === imgSrc) {
                        if(image.nextElementSibling) {
                            imgSrc = image.nextElementSibling.querySelector("img").src;
                            lightBoxImage.src = imgSrc;
                        }
                    }
                });
            });
        }
    };
    

    const prevImage = () => {
        if(weeks) {
            weeks.forEach((week) => {
                let images = week.querySelectorAll(".gallery-image");
                images.forEach((image) => {
                    if(image.querySelector("img").src === imgSrc) {
                        if(image.previousElementSibling) {
                            imgSrc = image.previousElementSibling.querySelector("img").src;
                            lightBoxImage.src = imgSrc;
                            
                        }
                    }
                });
            });
        }
    };
    
    const hideLightBox = () => {
        lightBox.classList.remove("show");
    };

    return (
        <div className="lightbox">
            <div className="lightbox-content">
                <div className="lightbox-header">
                    <button className="close" onClick={() => hideLightBox()}>
                        <svg width="30" height="30" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z"/>
                        </svg>
                    </button>
                </div>
                <div className="lightbox-body">
                    <img className="lightbox-img" src={imgSrc} alt="chantier" />
                    <button onClick={() => prevImage()} className="left-arrow" aria-label="Image precedente">
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.7 12.3C8.1 12.7 8.1 13.3 7.7 13.7C7.5 13.9 7.3 14 7 14C6.7 14 6.5 13.9 6.3 13.7L0.3 7.7C-0.1 7.3 -0.1 6.7 0.3 6.3L6.3 0.3C6.7 -0.1 7.3 -0.1 7.7 0.3C8.1 0.7 8.1 1.3 7.7 1.7L2.4 7L7.7 12.3Z"/>
                        </svg>
                    </button>
                    <button onClick={() => nextImage()} className="right-arrow" aria-label="Image suivante">
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.7 7.7L1.7 13.7C1.5 13.9 1.3 14 1 14C0.7 14 0.5 13.9 0.3 13.7C-0.1 13.3 -0.1 12.7 0.3 12.3L5.6 7L0.3 1.7C-0.1 1.3 -0.1 0.7 0.3 0.3C0.7 -0.1 1.3 -0.1 1.7 0.3L7.7 6.3C8.1 6.7 8.1 7.3 7.7 7.7Z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LightBox;