import { Fragment, useEffect, useState } from "react";
import "../styles/css/Pointage.css";

const Pointage = ({ data }) => {
    const pointage = data;

    const [pointageChantier, setPointageChantier] = useState([]);

    useEffect(() => {
        if (pointage) {
            setPointageChantier(pointage);
        }
    }, [pointage]);

    if (!pointageChantier.id) return null;

    return (
        <div className="pointage">
            <div className="pointage__info">
                Personnels: {pointageChantier.personnel}<br /><br />
                Bennes: {pointageChantier.bennes}
            </div>
        </div>
    );
}

export default Pointage;