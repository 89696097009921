import { useState, useEffect } from "react";
import { createChantier, getChantier, getChantiers } from "../../services/apiCall";
import { getInfoChantier } from "../../services/redux/chantierSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const ListeChantier = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [Chantiers, setChantiers] = useState([]);
    const [toggleShow, setToggleShow] = useState("");
    const [nom, setNom] = useState("");
    const [mandataire, setMandataire] = useState("");
    const [interlocuteur, setInterlocuteur] = useState("");
    const [adresse, setAdresse] = useState("");
    const [ville, setVille] = useState("");
    const [codePostal, setCodePostal] = useState("");
    const [dateDebut, setDateDebut] = useState("");
    const [dateFin, setDateFin] = useState("");

    useEffect(() => {
        (async () => {
            setChantiers(await getChantiers());
        })();
    }, []);

    const handleChantier = async (Chantier) => {
        let data = await getChantier(Chantier.password);
        dispatch(getInfoChantier(data));
        navigate("/chantier");
    }

    const openModif = () => {
        const modal = document.querySelector(".modal-chantier");
        modal.classList.add("open");
        document.body.classList.add("modal-open")
    };

    const closeModif = () => {
        const modal = document.querySelector(".modal-chantier");
        modal.classList.remove("open");
        document.body.classList.remove("modal-open");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("nom", nom);
        formData.append("mandataire", mandataire);
        formData.append("interlocuteur", interlocuteur);
        formData.append("adresse", `${adresse},${codePostal} ${ville} `);
        formData.append("dateDebut", dateDebut);
        formData.append("dateFin", dateFin);

        
        try {
            const res = await createChantier(formData);
            console.log(res);
        } catch (error) {
            console.log(error);
        }

        closeModif();
        return false;
    };

    const ShowPassword = (id) => {
        if (toggleShow === id) {
            setToggleShow("");
        } else setToggleShow(id);
    }

    return (
        <div className="container listeChantier">
            <div className="row">
                <div className="col-12">
                    <h1 className="text-center">Liste des Chantiers <button onClick={() => {
                        openModif();
                    }}>+</button>
                    </h1>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Nom</th>
                                <th scope="col">Mandataire</th>
                                <th scope="col">Début</th>
                                <th scope="col">Fin</th>
                                <th scope="col">identifiant</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Chantiers.map((Chantier) => (
                                <tr key={Chantier.id}>
                                    <td>{Chantier.nomChoix}</td>
                                    <td>{Chantier.mandataire}</td>
                                    <td>{new Date(Chantier.dateDebut).toLocaleDateString("fr")}</td>
                                    <td>{new Date(Chantier.dateFin).toLocaleDateString("fr")}</td>
                                    <td>
                                        <span>{toggleShow === Chantier.id  ? Chantier.password : "••••••"}</span>
                                        <svg className="eye" onClick={() => ShowPassword(Chantier.id)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23 24C23.3 24 23.5 23.9 23.7 23.7C24.1 23.3 24.1 22.7 23.7 22.3L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L4.6 6C2.8 7.5 1.2 9.4 0.1 11.5C0 11.8 0 12.1 0.1 12.4C0.3 12.8 4.5 21 12 21C14.1 21 16.1 20.4 17.8 19.2L22.3 23.7C22.5 23.9 22.7 24 23 24ZM9.7 3.3C9.1 3.4 8.8 3.9 8.9 4.5C9.1 5 9.6 5.3 10.1 5.4C10.7 5.3 11.4 5.2 12 5.2C17.4 5.2 21 10.6 21.9 12.2C21.4 13.1 20.8 13.9 20.1 14.7C19.7 15.1 19.8 15.7 20.2 16.1C20.3 16.2 20.6 16.3 20.8 16.3C21.1 16.3 21.4 16.1 21.6 15.9C22.5 14.8 23.3 13.7 23.9 12.5C24 12.2 24 11.9 23.9 11.6C23.7 11.2 19.5 3 12 3C11.2 3 10.4 3.1 9.7 3.3ZM9.9 12.1C9.9 12.6 10.1 13.1 10.5 13.5C11.1 14 11.8 14.1 12.5 13.9L10 11.4C10 11.5652 9.96969 11.7 9.94245 11.8212C9.9202 11.9202 9.9 12.0101 9.9 12.1ZM14 15.5L16.4 17.8C15 18.6 13.5 19 12 19C6.6 19 3 13.6 2.1 12C3.2 10.2 4.5 8.7 6 7.4L8.5 9.9C8.1 10.6 7.9 11.4 7.9 12.2C8 13.3 8.4 14.3 9.2 15C9.9 15.7 10.9 16.1 11.9 16.1C12.6 16.1 13.4 15.9 14 15.5Z" fill="white" />
                                        </svg>
                                    </td>
                                    <td>
                                        <button className="btn btn-primary" onClick={() => handleChantier(Chantier)}>Acceder</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="modal modal-chantier">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">Ajouter un chantier</h2>
                        <button className="modal-close" onClick={() => closeModif()}>
                            <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form encType="multipart/form-data" onSubmit={(e) => handleSubmit(e)}>
                            <div className="form-group">
                                <label htmlFor="nom">Nom</label>
                                <input required type="text" className="form-control" id="nom" value={nom} onChange={(e) => setNom(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="mandataire">Mandataire</label>
                                <input type="text" className="form-control" id="mandataire" value={mandataire} onChange={(e) => setMandataire(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="interlocuteur">Interlocuteur</label>
                                <input type="text" className="form-control" id="interlocuteur" value={interlocuteur} onChange={(e) => setInterlocuteur(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="adresse">Adresse</label>
                                <input type="text" className="form-control" id="adresse" value={adresse} onChange={(e) => setAdresse(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="code_postal">Code postal</label>
                                <input type="number" className="form-control" id="code_postal" value={codePostal} onChange={(e) => setCodePostal(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="ville">Ville</label>
                                <input type="text" className="form-control" id="ville" value={ville} onChange={(e) => setVille(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="dateDebut">Date de début</label>
                                <input required type="date" className="form-control" id="dateDebut" value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="dateFin">Date de fin</label>
                                <input type="date" className="form-control" id="dateFin" value={dateFin} onChange={(e) => setDateFin(e.target.value)} />
                            </div>
                            <button type="submit" className="btn btn-primary">Valider</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListeChantier;