import "../styles/css/Admin.css";
import ListePersonnel from "../components/admin/ListePersonnel";
import ListeChantier from "../components/admin/ListeChantier";

const Admin = () => {
    return (
        <main className="accueil">
            <div className="accueil__container">
                <ListeChantier />
                <ListePersonnel />
             </div>
        </main>
    )
}

export default Admin;