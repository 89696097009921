import { useState } from "react";
import { useEffect } from "react";
import { addPersonnelDocument, createPersonnel, deletePersonnels, getPersonnels, modifyPersonnel } from "../../services/apiCall";
import Documents from "../Documents";
import { useDispatch, useSelector } from "react-redux";
import { getInfoPersonnels } from "../../services/redux/personnelSlice";

const ListePersonnel = () => {
    const dispatch = useDispatch();
    const { personnels } = useSelector((state) => state.personnels.personnels);
    const { documents } = useSelector((state) => state.personnels.personnels);
    const [listDocuments, setListDocuments] = useState(documents || []);
    const [personnel, setPersonnel] = useState({});
    const [poste, setPoste] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [type, setType] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [persoId, setPersoId] = useState("");

    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    useEffect(() => {
        (async () => {
            await getPersonnels().then((data) => {
                dispatch(getInfoPersonnels(data));
            });
        })();
        if (personnel.nom !== undefined) {
            setNom(personnel.nom);
            setPrenom(personnel.prenom);
            setPoste(personnel.poste.toLowerCase());
        }

    }, [personnel, dispatch]);

    const openFile = (e) => {
        const modal = e.currentTarget.nextElementSibling;
        modal.classList.add("open");
        document.body.classList.add("modal-open")
    };

    const openPerso = (e) => {
        const modal = e.currentTarget.parentElement.querySelector(".modal-perso");
        modal.classList.add("open");
        document.body.classList.add("modal-open")
        window.onclick = (event) => {
            if (event.target === modal) {
                closePerso()
            }
        }
    };

    const closePerso = () => {
        const modal = document.querySelector(".modal-perso.open");
        modal.classList.remove("open");
        document.body.classList.remove("modal-open")
    };

    const openModif = () => {
        const modal = document.querySelector(".modal-modif");
        modal.classList.add("open");
        document.body.classList.add("modal-open")
    };

    const closeModif = () => {
        const modal = document.querySelector(".modal-modif");
        modal.classList.remove("open");
        document.body.classList.remove("modal-open");
        setPersonnel({});
        setNom("");
        setPrenom("");
        setPoste("");
        setFile();
        setFileName("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("nom", nom);
        formData.append("prenom", prenom);
        formData.append("poste", poste);
        formData.append("file", file);
        formData.append("fileName", fileName);

        if (type === "add") {
            try {
                const res = await createPersonnel(formData);
                console.log(res);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const res = await modifyPersonnel(formData, personnel.id);
                console.log(res);
            } catch (error) {
                console.log(error);
            }
        }
        closeModif();
        return false;
    };

    const deletePers = async (personnel_id) => {
        let result = window.confirm("Voulez-vous vraiment supprimer ce personnel ?");
        if (result) {
            try {
                const res = await deletePersonnels(personnel_id);
                console.log(res);
            } catch (error) {
                console.log(error);
            }
        }
        setPersonnel({});
    };

    const openAddFile = () => {
        const modal = document.querySelector(".modal-addFile");
        modal.classList.add("open");
        document.body.classList.add("modal-open")
        window.onclick = (event) => {
            if (event.target === modal) {
                closeAddFile()
            }
        }
    };

    const closeAddFile = () => {
        setPersoId("");
        document.querySelector(".modal-addFile form").reset();
        const modal = document.querySelector(".modal-addFile.open");
        modal.classList.remove("open");
        document.body.classList.remove("modal-open")
    };

    const handleAddFile = async (e) => {
        e.preventDefault();
        console.log(e.target.files.files[0]);
        const formData = new FormData();
        let nom = e.target.nom.value;
        const file = e.target.files.files[0];
        formData.append("nom", nom);
        formData.append("file", file);
        try {
            const res = await addPersonnelDocument(formData, persoId);
            console.log(res);
            if (res.status === 200) {
                setListDocuments([...listDocuments, res.data]);
            }
        } catch (err) {
            console.log(err);
        }
        closeAddFile();
    };

    return (
        <div className="container listePersonnel">
            <div className="row">
                <div className="col-12">
                    <h1 className="text-center">Liste du personnel <button onClick={() => {
                        setType("add");
                        openModif();
                    }}>+</button></h1>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Prenom</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Fonction</th>
                                <th scope="col">Document</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {personnels ? personnels.map((personnel) => (
                                <tr key={personnel.id}>
                                    <td>{personnel.prenom}</td>
                                    <td>{personnel.nom}</td>
                                    <td>{personnel.poste}</td>
                                    <td><span className="weeksBtn" onClick={(e) => openPerso(e)}>
                                        Voir
                                    </span>
                                        <div className="modal modal-perso">
                                            <div className="modal-content">
                                                <div className="personnel-header">
                                                    <h3>{personnel.nom} {personnel.prenom}</h3>
                                                    <p>{personnel.poste}</p>
                                                    <button className="modal-close" onClick={() => closePerso()}>
                                                        <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div className="files" key={personnel.id}>
                                                    <button className="weeksBtn" onClick={(e) => openFile(e, personnel.proBTP)}>
                                                        <h3>ProBTP</h3>
                                                    </button>
                                                    <Documents files={personnel.proBTP} />
                                                    {
                                                        [...listDocuments].map((doc) => (
                                                            doc.personnel_id === personnel.id) ? (
                                                            <div key={doc.id}>
                                                                <button className="weeksBtn" onClick={(e) => openFile(e, doc.lien)}>
                                                                    <h3>{doc.nom}</h3>
                                                                </button>
                                                                <Documents files={doc.lien} />
                                                            </div>
                                                        ) : null
                                                        )
                                                    
                                                    }
                                                    <svg className="btnAddTask" onClick={() => { setPersoId(personnel.id); openAddFile(personnel.id) }} width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17 0H3C1.3 0 0 1.3 0 3V17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V3C20 1.3 18.7 0 17 0ZM18 17C18 17.6 17.6 18 17 18H3C2.4 18 2 17.6 2 17V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V17ZM14 11C14.6 11 15 10.6 15 10C15 9.4 14.6 9 14 9H11V6C11 5.4 10.6 5 10 5C9.4 5 9 5.4 9 6V9H6C5.4 9 5 9.4 5 10C5 10.6 5.4 11 6 11H9V14C9 14.6 9.4 15 10 15C10.6 15 11 14.6 11 14V11H14Z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <button className="btn btn-primary" onClick={() => {
                                            setType("modif");
                                            setPersonnel(personnel);
                                            openModif();
                                        }}>Modifier</button>
                                        <button className="btn btn-danger" onClick={() => deletePers(personnel.id)}>Supprimer</button>
                                    </td>
                                </tr>
                            )) : <tr><td colSpan="5">Aucun personnel</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="modal modal-modif">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>{type === "add" ? "Ajouter du" : "Modifier le"} personnel</h2>
                        <button className="modal-close" onClick={() => closeModif()}>
                            <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form encType="multipart/form-data" onSubmit={(e) => handleSubmit(e)}>
                            <div className="form-group">
                                <label htmlFor="prenom">Prenom</label>
                                <input type="text" className="form-control" id="nom" value={prenom} onChange={(e) => setPrenom(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="nom">Nom</label>
                                <input type="text" className="form-control" id="nom" value={nom} onChange={(e) => setNom(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="fonction">Fonction</label>
                                <select className="form-control" id="fonction" value={poste !== "" ? poste : setPoste("ouvrier")} onChange={(e) => setPoste(e.target.value)}>
                                    <option value="ouvrier">Ouvrier</option>
                                    <option value="chef d'équipe">Chef d'équipe</option>
                                    <option value="chef de chantier">Chef de chantier</option>
                                    <option value="conducteur de travaux">Conducteur de travaux</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="proBTP">Pro BTP</label>
                                <input type="file" className="form-control-file" id="proBTP" onChange={(e) => saveFile(e)} />
                            </div>
                            <button type="submit" className="btn btn-primary">Valider</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal modal-addFile">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3>Ajouter Document</h3>
                        <button className="modal-close" onClick={() => closeAddFile()}>
                            <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="file-form">
                        <form encType="multipart/form-data" onSubmit={(e) => handleAddFile(e)}>
                            <div className="form-group">
                                <label htmlFor="nom">Nom du fichier </label>
                                <input type="text" name="nom" id="nom" placeholder="Nom du fichier" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="files">Fichier</label>
                                <input type="file" name="files" id="files" accept="application/pdf" required />
                            </div>
                            <button type="submit">Valider</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListePersonnel;