import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modifyChantier } from "../../services/apiCall";
import { updateInfoChantier } from "../../services/redux/chantierSlice";



const EditDesc = () => {
    const isLogged = useSelector(state => state.admin.connected);
    const chantier = useSelector((state) => state.chantier.chantier);
    const dispatch = useDispatch();
    const [dateDebut, setDateDebut] = useState(new Date(chantier.dateDebut).toLocaleDateString('en-CA'));
    const [dateFin, setDateFin] = useState(new Date(chantier.dateFin).toLocaleDateString('en-CA'));
    let adress = chantier.adresse.split(",");
    let ville = "", codePostal = "";

    if(adress.length > 1) {
        ville = adress[1].slice(-5);
        codePostal = adress[1].slice(1,-5);
    }

    useEffect(() => {
        (async () => {
            if(isLogged) {
                const btn = document.querySelector(".editDescBtn");
                btn.addEventListener("click", openEditDesc);
            }
        })()
    }, [isLogged])

    const openEditDesc = () => {
        const modal = document.querySelector(".editModal");
        modal.style.display = "block";
        document.body.classList.add("modal-open")
        window.onclick = (event) => {
            if(event.target === modal) {
                closeEditDesc()
            }
        }
    }
    
    const closeEditDesc = () => {
        const modal = document.querySelector(".editModal");
        document.body.classList.remove("modal-open")
        modal.style.display = "none";
    }

    const handleEditDesc = async (e) => {
        e.preventDefault();
        let data = {
            nomChoix: e.target.nom.value.trim() !== '' ? e.target.nom.value : chantier.nomChoix,
            adresse: `${e.target.adresse.value.trim() !== '' ? e.target.adresse.value : adress[0]}, ${e.target.codePostal.value.trim() !== '' ? e.target.codePostal.value : codePostal} ${e.target.ville.value.trim() !== '' ? e.target.ville.value : ville}`,
            dateDebut: e.target.dateDebut.value.trim() !== '' ? e.target.dateDebut.value : chantier.dateDebut,
            dateFin: e.target.dateFin.value.trim() !== '' ? e.target.dateFin.value : chantier.dateFin,
            mandataire: e.target.mandataire.value.trim() !== '' ? e.target.mandataire.value : chantier.mandataire,
            interlocuteur: e.target.interlocuteur.value.trim() !== '' ? e.target.interlocuteur.value : chantier.interlocuteur,
        }

        try {
            const res = await modifyChantier(data, chantier.id);
            if(res.status === 200) {
                console.log(res);
                dispatch(updateInfoChantier(data));
            }
        }
        catch (error) {
            console.log(error);
        }
        closeEditDesc();
    }

    return (
        <div className="editModal modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Modifier la description</h2>
                        <button className="modal-close" onClick={() => closeEditDesc()}>
                            <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="editDescForm" onSubmit={(e) => handleEditDesc(e)} >
                            <div className="form-group">
                                <label htmlFor="nom">Nom du chantier</label>
                                <input type="text" className="form-control" id="nom" placeholder={chantier.nomChoix} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="adresse">Adresse</label>
                                <input type="text" id="adresse" name="adresse" placeholder={chantier.adresse.split(",")[0]} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="ville">Ville</label>
                                <input type="text" id="ville" name="ville" placeholder={ville} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="codePostal">Code Postal</label>
                                <input type="text" id="codePostal" name="codePostal" placeholder={codePostal} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="mandataire">Mandataire</label>
                                <input type="text" id="mandataire" name="mandataire" placeholder={chantier.mandataire} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="interlocuteur">Interlocuteur</label>
                                <input type="text" id="interlocuteur" name="interlocuteur" placeholder={chantier.interlocuteur} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="dateDebut">Début</label>
                                <input type="date" id="dateDebut" name="dateDebut" value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="dateFin">Fin </label>
                                <input type="date" id="dateFin" name="dateFin" value={dateFin} onChange={(e) => setDateFin(e.target.value)} />
                            </div>
                            <input type="submit" value="Modifier" />
                        </form>
                    </div>
                </div>
            </div>
    )
}

export default EditDesc;