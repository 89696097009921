import "../styles/css/Description.css";
  
const Description = (data) => {

    const { adresse, mandataire, interlocuteur, dateDebut, dateFin } = data.data;

    //change date format
    const formatDate = (date) => {
        date = new Date(date);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if(day < 10) day = "0" + day;
        if(month < 10) month = "0" + month;

        return `${day}/${month}/${year}`;
    };

    return (
        <section className="description">
            <div>
                <h3>Adresse: <span>{adresse}</span></h3>
                <h3>Mandataire: <span>{mandataire}</span></h3>
                <h3>Interlocuteur: <span>{interlocuteur}</span></h3>
            </div>
            <div>
                <h3>Date de Début: <span>{formatDate(dateDebut)}</span></h3>
                <h3>Date de fin prévue: <span>{formatDate(dateFin)}</span></h3>
            </div>
        </section>
    );
};

export default Description;