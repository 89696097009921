
import { useEffect, useState } from "react";
import Pointage from "./Pointage";
import "../styles/css/Planning.css";
import { useSelector } from "react-redux";
import { createPlanningTask, createPointage, deletePlanningTask, updatePlanningTask, updatePointage } from "../services/apiCall";

const Planning = () => {
    const chantier = useSelector((state) => state.chantier.chantier);
    const planning = useSelector((state) => state.chantier.planning);
    const pointages = useSelector((state) => state.chantier.pointages);
    const [pointageList, setPointageList] = useState(pointages);
    const [planningList, setPlanningList] = useState(planning);
    const isLogged = useSelector((state) => state.admin.connected);
    const [planningChantier, setPlanningChantier] = useState([]);
    const debut = new Date(chantier.dateDebut);
    const fin = new Date(chantier.dateFin);
    const month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const day = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
    const [date, setDate] = useState(new Date());
    const today = new Date();
    const [dateStart, setDateStart] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dateEnd, setDateEnd] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    const [plann, setPlann] = useState([]);
    const [type, setType] = useState();
    const [task, setTask] = useState({});
    const [dateDebut, setDateDebut] = useState("");
    const [dateFin, setDateFin] = useState("");
    const [dateDebutReel, setDateDebutReel] = useState("");
    const [dateFinReel, setDateFinReel] = useState("");
    const [tache, setTache] = useState("");
    const [taskId, setTaskId] = useState();
    const jour = document.querySelectorAll(".jour");
    const [scroll, setScroll] = useState(0);
    const [pointage , setPointage] = useState({});
    const [pointageId, setPointageId] = useState("");
    const [pointageDate, setPointageDate] = useState();
    const [pointagePersonnel, setPointagePersonnel] = useState(0);
    const [pointageBennes, setPointageBennes] = useState(0);

    useEffect(() => {
        const tab = document.querySelector(".planning-content");
        tab.addEventListener("scroll", () => {
            setScroll(tab.scrollLeft);
        });
    }, []);

    jour.forEach((item) => {
        if(isLogged) {item.style.cursor = "pointer";}
        item.addEventListener("mouseover", () => {
            if (item.querySelector(".pointage") === null) return;
            const point = item.querySelector(".pointage");
            point.style.top = `${item.offsetTop - 90}px`;
            point.style.left = `${item.offsetLeft - scroll - 45}px`;
        });
    });

    useEffect(() => {
        document.querySelector(".currentMonth").addEventListener("click", () => {
            currentMonth();
        });
        modifPlanning();
    }, [chantier, planning, planningList]);

    useEffect(() => {
        if (task.tache !== undefined) {
            setTaskId(task.id)
            setTache(task.tache)
            setDateDebut(task.debutPrevu.toLocaleDateString('en-CA'))
            setDateFin(task.finPrevu.toLocaleDateString('en-CA'))
            if (task.debutReel !== "") setDateDebutReel(task.debutReel.toLocaleDateString('en-CA'))
            if (task.finReel !== "") setDateFinReel(task.finReel.toLocaleDateString('en-CA'))
        }
    }, [task])

    useEffect(() => {
        if(pointage !== undefined) {
            if (pointage.date !== undefined) {
                setPointageId(pointage.id)
                setPointagePersonnel(pointage.personnel)
                setPointageBennes(pointage.bennes)
            }
        }
    }, [pointage])

    const modifPlanning = () => {
        if (planningList.length > 0) {
            const modifPlanning = planningList.map((item) => {
                return {
                    ...item,
                    debutPrevu: new Date(item.debutPrevu),
                    finPrevu: new Date(item.finPrevu),
                    debutReel: item.debutReel !== "" ? new Date(item.debutReel) : "",
                    finReel: item.finReel !== "" ? new Date(item.finReel) : "",
                    showReel: item.debutReel !== "" ? true : false,
                }
            });
            modifPlanning.forEach((item) => {
                item.debutPrevu = new Date(item.debutPrevu);
                item.debutPrevu.setHours(23, 59, 59);
                item.finPrevu = new Date(item.finPrevu);
                item.finPrevu.setHours(23, 59, 59);
                if (item.debutReel !== "") {
                    item.debutReel = new Date(item.debutReel);
                    item.debutReel.setHours(23, 59, 59);
                }
                if (item.finReel !== "") {
                    item.finReel = new Date(item.finReel);
                    item.finReel.setHours(23, 59, 59);
                }
            });
            setPlanningChantier(modifPlanning);
        }
    }

    useEffect(() => {
        const monthPlanning = () => {
            let planningMonth = [];
            if (planningChantier.length > 0) {
                planningChantier.forEach((item) => {
                    if ((item.debutPrevu.getMonth() <= dateStart.getMonth() && item.finPrevu.getMonth() >= dateEnd.getMonth() && item.debutPrevu.getFullYear() <= dateStart.getFullYear() && item.finPrevu.getFullYear() >= dateEnd.getFullYear()) ||
                        (item.debutReel.getMonth() <= dateStart.getMonth() && item.finReel.getMonth() >= dateEnd.getMonth() && item.debutReel.getFullYear() <= dateStart.getFullYear() && item.finReel.getFullYear() >= dateEnd.getFullYear())) {
                        let task = Object.assign({}, item);
                        if (task.finPrevu > dateEnd) {
                            task.finPrevu = dateEnd;
                        }
                        else if (task.debutPrevu < dateStart) {
                            task.debutPrevu = dateStart;
                        }
                        if (task.debutReel < dateStart) {
                            task.debutReel = dateStart;
                        }
                        if (item.debutReel === "") {
                            item.debutReel = today;
                            task.debutReel = today;
                        }
                        if (item.finReel === "") {
                            item.finReel = today;
                            task.finReel = today;
                        }
                        if (task.finReel > dateEnd) {
                            if (item.finReel > item.finPrevu) {
                                task.retard = true;
                            }
                            task.finReel = dateEnd;
                        }
                        planningMonth.push(task);
                    }
                });
            }
            orderPlanning(planningMonth);
            setPlann(planningMonth);
        };
        monthPlanning();
        if (debut.getMonth() + 1 > date.getMonth() && debut.getFullYear() >= date.getFullYear()) {
            document.querySelector(".prevMonth").classList.add("d-none");
        }
        else {
            document.querySelector(".prevMonth").classList.remove("d-none");
        }
        if (fin.getMonth() - 1 < date.getMonth() || fin.getFullYear() < date.getFullYear()) {
            document.querySelector(".nextMonth").classList.add("d-none");
        }
        else {
            document.querySelector(".nextMonth").classList.remove("d-none");
        }
    }, [dateStart, dateEnd, planning, date, planningChantier]);

    const monthPrev = () => {
        if (debut < date) {
            setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
            setDateStart(new Date(date.getFullYear(), date.getMonth() - 1, 1));
            setDateEnd(new Date(date.getFullYear(), date.getMonth(), 0));
        }
    }

    const monthNext = () => {
        setDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
        setDateStart(new Date(date.getFullYear(), date.getMonth() + 1, 1));
        setDateEnd(new Date(date.getFullYear(), date.getMonth() + 2, 0));
    }

    const currentMonth = () => {
        setDate(new Date());
        setDateStart(new Date(date.getFullYear(), date.getMonth(), 1));
        setDateEnd(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    }

    // sort by date
    const orderPlanning = (planning) => {
        planning.sort((a, b) => {
            return a.debutPrevu - b.debutPrevu;
        });
    }

    const openPlann = () => {
        const modal = document.querySelector(`.modal-task`);
        modal.style.display = "block";
        document.body.classList.add("modal-open")
        if (planningList.filter((item) => {
            if (item.id === taskId) {
                if (item.debutReel === "") return true;
            }
        }).length > 0) {
            setDateDebutReel("");
            setDateFinReel("");
        }

        window.onclick = (event) => {
            if (event.target === modal) {
                closePlann()
            }
        }
    }

    const closePlann = () => {
        setTask({});
        setTaskId("");
        setTache("");
        setDateDebut("");
        setDateFin("");
        setDateDebutReel("");
        setDateFinReel("");
        const modal = document.querySelector(`.modal-task`);
        modal.style.display = "none";
        document.body.classList.remove("modal-open")
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            id: taskId,
            tache: tache,
            debutPrevu: dateDebut,
            finPrevu: dateFin,
            debutReel: dateDebutReel,
            finReel: dateFinReel,
        }
        console.log(data);
        if (type === "add") {
            try {
                const res = await createPlanningTask(data, chantier.id);
                console.log(res);
                if (res.status === 200) {
                    setPlanningList(planningList.concat(res.data));
                    modifPlanning();
                }
            } catch (err) {
                console.log(err);
            }
        }
        else if (type === "edit") {
            const res = await updatePlanningTask(data, chantier.id);
            console.log(res);
            if (res.status === 200) {
                const newPlanning = planningList.map((item) => {
                    console.log(item);
                    if (item.id === res.data.id) {
                        return res.data;
                    }
                    else {
                        return item;
                    }
                });
                console.log(newPlanning);
                setPlanningList(newPlanning);
                modifPlanning();
            }
        }

        closePlann();
    }

    const deleteTask = async (id) => {
        console.log(id);
        let result = window.confirm("Voulez-vous vraiment supprimer ce personnel ?");
        if (result) {
            const res = await deletePlanningTask(id, chantier.id);
            console.log(res);
            if (res.status === 200) {
                const newPlanning = planningList.filter((item) => {
                    return item.id !== id;
                });
                setPlanningList(newPlanning);
                modifPlanning();
            }
        }
    }

    const openPointage = () => {
        if(!isLogged) {
            return;
        }
        const modal = document.querySelector(`.pointage__modal`);
        modal.style.display = "block";
        document.body.classList.add("modal-open")
        window.onclick = (event) => {
            if (event.target === modal) {
                closePointage()
            }
        }
    }

    const closePointage = () => {
        setPointage({});
        setPointageId("")
        setPointageDate("");
        setPointagePersonnel(0);
        setPointageBennes(0);
        const modal = document.querySelector(`.pointage__modal`);
        modal.style.display = "none";
        document.body.classList.remove("modal-open")
    }

    const handlePointage = async (e) => {
        e.preventDefault();
        let data = {
            id: pointageId,
            date: pointageDate,
            personnel: pointagePersonnel,
            bennes: pointageBennes,
        }
        if (pointageId === "") {
            try {
                const res = await createPointage(data, chantier.id);
                console.log(res);
                if (res.status === 200) {
                    setPointageList(pointageList.concat(res.data));
                    modifPlanning();
                }
            } catch (err) {
                console.log(err);
            }
        }
        else {
            const res = await updatePointage(data, chantier.id);
            console.log(res);
            if (res.status === 200) {
                const newPointage = pointageList.map((item) => {
                    console.log(item);
                    if (item.id === res.data.id) {
                        return res.data;
                    }
                    else {
                        return item;
                    }   
                });
                console.log(newPointage);
                setPointageList(newPointage);
                modifPlanning();
            }
        }
        closePointage();
    }

    return (
        <section className="planning">
            <h2>Planning du chantier
                {isLogged ?
                    <svg className="btnAddTask" onClick={() => { setType("add"); openPlann() }} width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 0H3C1.3 0 0 1.3 0 3V17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V3C20 1.3 18.7 0 17 0ZM18 17C18 17.6 17.6 18 17 18H3C2.4 18 2 17.6 2 17V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V17ZM14 11C14.6 11 15 10.6 15 10C15 9.4 14.6 9 14 9H11V6C11 5.4 10.6 5 10 5C9.4 5 9 5.4 9 6V9H6C5.4 9 5 9.4 5 10C5 10.6 5.4 11 6 11H9V14C9 14.6 9.4 15 10 15C10.6 15 11 14.6 11 14V11H14Z" />
                    </svg> : null
                }
            </h2>
            <hr className='subt' />
            <div className="planning-container">
                <div className="planning-header">
                    <button className="prevMonth" onClick={() => monthPrev()}>
                        <h3>Mois précédent</h3>
                    </button>
                    <button className="nextMonth" onClick={() => monthNext()}>
                        <h3>Mois suivant</h3>
                    </button>
                    <button className="currentMonth">
                        <h3>Mois en cours</h3>
                    </button>
                </div>
                <div className="abs">
                    <div className="planning-content">
                        <table className="planning-table">
                            <tbody>
                                <tr className="listDay">
                                    <td id="mois" className={(date.getMonth() === today.getMonth()) ? "curMonth" : ""}>{month[date.getMonth()] + " " + date.getFullYear()}</td>
                                    {
                                        [...Array(dateEnd.getDate() - dateStart.getDate() + 1)].map((_, i) => {
                                            const da = (((dateStart.getDay() - 1) + i) % 7);
                                            let d = new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate() + i).toLocaleDateString('en-CA')
                                            let point = pointageList.find((item) => {
                                                let date = new Date(item.date);
                                                return (date.getDate() === (dateStart.getDate() + i) && date.getMonth() === dateStart.getMonth() && date.getFullYear() === dateStart.getFullYear());
                                            });
                                            if (da < 5 && da >= 0) {
                                                if (dateStart.getDate() + i === today.getDate() && dateStart.getMonth() === today.getMonth() && dateStart.getFullYear() === today.getFullYear()) {
                                                    return (
                                                        <td key={i}  onClick={() => { setPointageDate(d); setPointage(point); openPointage()}} className={(da === 0) ? "jour border today" : "jour today"}>{day[da] + " " + (((dateStart.getDate() + i) < 10) ? ("0" + (dateStart.getDate() + i)) : (dateStart.getDate() + i))}
                                                            <Pointage data={point} />
                                                        </td>
                                                    );
                                                }
                                                else {
                                                    return (
                                                        <td key={i} onClick={() => { setPointageDate(d); setPointage(point); openPointage() }} className={(da === 0) ? "jour border" : "jour"}>{day[da] + " " + (((dateStart.getDate() + i) < 10) ? ("0" + (dateStart.getDate() + i)) : (dateStart.getDate() + i))}
                                                            <Pointage data={point} />
                                                        </td>
                                                    );
                                                }
                                            }
                                            else {
                                                return "";
                                            }
                                        })
                                    }
                                </tr>
                                {
                                    plann.map((plan, index) => {
                                        return ([
                                            <tr key={index + " prevu"}>

                                                <td className="task" rowSpan="2" >{plan.tache}</td>
                                                {
                                                    [...Array(dateEnd.getDate() - dateStart.getDate() + 1)].map((_, i) => {
                                                        const da = (((dateStart.getDay() - 1) + i) % 7);
                                                        if (da < 5 && da >= 0) {
                                                            if ((plan.debutPrevu.getDate()) <= (dateStart.getDate() + i) && (plan.finPrevu.getDate()) >= (dateStart.getDate() + i) && (plan.debutPrevu.getMonth()) === (date.getMonth()) && (plan.finPrevu.getMonth()) === (date.getMonth())) {
                                                                return (
                                                                    <td key={i} className="prevu"></td>
                                                                );
                                                            }
                                                            else {
                                                                return (
                                                                    <td key={i}></td>
                                                                );
                                                            }
                                                        }
                                                        else {
                                                            return "";
                                                        }
                                                    })
                                                }
                                                {isLogged ?
                                                    <td className="planningAction" rowSpan="2">
                                                        <button className="btn btn-primary" onClick={() => { setType("edit"); setTask(plan); openPlann() }}>Modifier</button>
                                                        <button className="btn btn-danger" onClick={() => deleteTask(plan.id)}>Supprimer</button>
                                                    </td>
                                                    : null
                                                }
                                            </tr>
                                            ,
                                            <tr key={index + " reel"}>
                                                {
                                                    [...Array(dateEnd.getDate() - dateStart.getDate() + 1)].map((_, i) => {
                                                        const da = (((dateStart.getDay() - 1) + i) % 7);
                                                        if (da < 5 && da >= 0) {
                                                            if (plan.showReel && ((plan.debutReel.getDate()) <= (dateStart.getDate() + i) && (plan.finReel.getDate()) >= (dateStart.getDate() + i) && (plan.debutReel.getMonth()) === (date.getMonth()) && (plan.finReel.getMonth()) === (date.getMonth()))) {
                                                                return (
                                                                    <td key={i} className={(plan.finReel > plan.finPrevu || plan.retard) ? "late" : "intime"}></td>
                                                                );
                                                            }
                                                            else {
                                                                return (
                                                                    <td key={i}></td>
                                                                );
                                                            }
                                                        }
                                                        else {
                                                            return "";
                                                        }
                                                    })
                                                }
                                            </tr>
                                        ]);
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isLogged ?
            <div className="modal modal-task">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>{type === "add" ? "Ajouter une" : "Modifier la"} tâche</h2>
                        <button className="modal-close" onClick={() => closePlann()}>
                            <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form encType="multipart/form-data" onSubmit={(e) => handleSubmit(e)}>
                            <div className="form-group">
                                <label htmlFor="tache">Tâche</label>
                                <input type="text" required className="form-control" id="tache" value={tache} onChange={(e) => setTache(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="debutPrevu">Début Prévue</label>
                                <input type="date" required className="form-control" id="debutPrevu" value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="finPrevu">Fin Prévue</label>
                                <input type="date" required className="form-control" id="finPrevu" value={dateFin} onChange={(e) => setDateFin(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="debutReel">Début Réel</label>
                                <input type="date" className="form-control" id="debutReel" value={dateDebutReel} onChange={(e) => setDateDebutReel(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="finReel">Fin Réel</label>
                                <input type="date" className="form-control" id="finReel" value={dateFinReel} onChange={(e) => setDateFinReel(e.target.value)} />
                            </div>
                            <button type="submit" className="btn btn-primary">Valider</button>
                        </form>
                    </div>
                </div>
            </div>: null}
            {isLogged ?
            <div className="modal pointage__modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Pointage</h2>
                        <button className="modal-close" onClick={() => closePointage()}>
                            <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form encType="multipart/form-data" onSubmit={(e) => handlePointage(e)}>
                            <div className="form-group">
                                <label htmlFor="personnel">Personnel</label>
                                <input type="number" required className="form-control" id="personnel" value={pointagePersonnel} onChange={(e) => setPointagePersonnel(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="bennes">Bennes</label>
                                <input type="number" required className="form-control" id="bennes" value={pointageBennes} onChange={(e) => setPointageBennes(e.target.value)} />
                            </div>
                            <button type="submit" className="btn btn-primary">Valider</button>
                        </form>
                    </div>
                </div>
            </div>
            : null}
        </section>
    );
};

export default Planning;