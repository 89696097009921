import { configureStore } from "@reduxjs/toolkit";
import chantierSlice from "./chantierSlice";
import adminSlice from "./adminSlice";
import personnelSlice from "./personnelSlice";

export const store = configureStore({
    reducer: {
        chantier: chantierSlice,
        admin: adminSlice,
        personnels: personnelSlice
    }
});