import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Description from '../components/Description';
import Files from '../components/Files';
import Gallery from '../components/Gallery';
import Planning from '../components/Planning';
import Personnels from '../components/Personnels';
import { cleanInfoChantier } from '../services/redux/chantierSlice';
import "../styles/css/Chantier.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import EditDesc from '../components/admin/EditDesc';

const Chantier = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLogged = useSelector(state => state.admin.connected);
    const chantier = useSelector((state) => state.chantier.chantier);
    const documents = useSelector((state) => state.chantier.documents);

    useEffect (() => {
        if(chantier.id === 0) {
            dispatch(cleanInfoChantier());
            navigate("/");
        }
        console.log(documents);
    }, [chantier, navigate, dispatch]);

    return (
        <main className='chantier'>
            <div className='title'>
                <h1>{chantier.nomChoix.toUpperCase()}
                    {isLogged ? (
                    <svg className='editDescBtn' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="white" d="M21.7 5.7L11.7 15.7C11.5 15.9 11.3 16 11 16H7C6.4 16 6 15.6 6 15V11C6 10.7 6.1 10.5 6.3 10.3L16.3 0.3C16.7 -0.1 17.3 -0.1 17.7 0.3L21.7 4.3C22.1 4.7 22.1 5.3 21.7 5.7ZM20 13.7V19C20 20.7 18.7 22 17 22H3C1.3 22 0 20.7 0 19V5C0 3.3 1.3 2 3 2H8.3C8.9 2 9.3 2.4 9.3 3C9.3 3.6 8.9 4 8.3 4H3C2.4 4 2 4.4 2 5V19C2 19.6 2.4 20 3 20H17C17.6 20 18 19.6 18 19V13.7C18 13.1 18.4 12.7 19 12.7C19.6 12.7 20 13.1 20 13.7ZM17 2.4L19.6 5L10.6 14H8V11.4L17 2.4Z"/>
                    </svg>) : null
                    }
                </h1>
                <hr />
            </div>
            <Description data={chantier} />
            <Gallery data={documents.filter((doc) => doc.type === "photo")} />
            <Planning />
            {/* <Messagerie /> */}
            <div className="files-zone">
                <Files type="Reporting" data={documents.filter((doc) => doc.type === "reporting")}/>
                <Files type="bdd" data={documents.filter((doc) => doc.type === "bdd")}/>
                <Files type="BSD" data={documents.filter((doc) => doc.type === "bsd")}/>
            </div>
            <Personnels />
            <Files type="Administratif" data={documents.filter((doc) => doc.type === "administratif")}/>
            {isLogged ? <EditDesc chantier={chantier} /> : null}
        </main>
    );
}

export default Chantier;