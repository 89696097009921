import { createSlice } from "@reduxjs/toolkit"
// all personnels
export const personnelSlice = createSlice({
    name: "personnels",
    initialState: {
        personnels: [
            {
                id: 0,
                nom: "",
                prenom: "",
                poste: "",
                ProBTP: ""
            }
        ],
        documents: []
    },
    reducers: {
        getInfoPersonnels: (state, action) => {
            state.personnels = action.payload;
            action.payload.documents ? state.documents = action.payload.documents : state.documents = []
        },
        updatePersonnels: (state, action) => {
            state.personnels = action.payload
        },
        updateInfoPersonnelsDocuments: (state, action) => {
            state.documents.push(
                {
                    id: action.payload.id,
                    personnel_id: action.payload.personnel_id,
                    nom: action.payload.nom,
                    lien: action.payload.lien,
                }
            )
        }
    }
})
export const {getInfoPersonnels, updatePersonnels, updateInfoPersonnelsDocuments } = personnelSlice.actions
export default personnelSlice.reducer